<template>
	<div class="leaderboard">
		<div class="main">
			<p class="title">LEADBOARD</p>
			<table>
				<tr>
					<th width="10%"></th>
					<th width="65%">ADDRESS</th>
					<th width="25%">BOXES BOUGHT</th>
				</tr>
				<tr v-for="(item, i) in 10" :key="i">
					<td>{{ i + 1 }}</td>
					<td>0xf42cb…b056f2</td>
					<td>42,321</td>
				</tr>
			</table>
			<div class="foot">
				<v-btn
					text
					color="#E95A29"
				>
					More
					<v-icon>mdi-chevron-down</v-icon>
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Leaderboard',
}
</script>

<style lang="scss" scoped>
.leaderboard {
	table {
		width: 100%;
		tr {
			td {
				&:last-child {
					font-weight: 500;
					font-size: 14px;
					line-height: 21px;
					color: #ACB1B6;
				}
				&:first-child {
					font-weight: bold;
					font-size: 14px;
					line-height: 21px;
					color: #67717A;
				}
				&:nth-child(2) {
					font-weight: 500;
					font-size: 14px;
					line-height: 21px;
					color: #7AA8DF;
				}
				padding-bottom: 30px;
			}
			th {
				text-align: left;
				font-weight: bold;
				font-size: 14px;
				line-height: 21px;
				color: #67717A;
				padding-bottom: 40px;
			}
		}
	}
	.title {
		font-weight: bold;
		font-size: 24px;
		line-height: 36px;
		color: #E8E8E8;
		text-align: center;
	}
	.foot {
		margin-top: 50px;
		padding-top: 10px;
		text-align: right;
		border-top: 1px solid #434A52;
	}
	.main {
		max-width: 589px;
		width: 100%;
	}
	display: flex;
	justify-content: center;
	padding: 80px 0;
}
</style>
